import React from 'react';

import cx from 'classnames';
import { FaTimes } from 'react-icons/fa';
import Icon, { IconProps } from 'src/components/icon/icon';

import * as styles from './floating-notification.module.less';

type SizeVariant = 'sm' | 'lg';

interface Props {
  message: string;
  icon?: IconProps;
  onClose?: () => any;
  actionButton?: React.ReactNode;
  variant?: 'success' | 'neutral' | 'download' | 'downloaded';
  hideCloseButton?: boolean;
  size?: SizeVariant;
}

const FloatingNotification: React.FC<Props> = ({
  message,
  onClose,
  icon,
  actionButton,
  variant = 'neutral',
  hideCloseButton,
  size = 'lg',
}) => {
  return (
    <aside
      className={cx(styles[`${variant}Floating`], {
        [styles[`${size}Size`]]: size,
      })}
    >
      {!hideCloseButton && (
        <button className={styles[`${variant}Close`]} onClick={onClose}>
          <FaTimes />
        </button>
      )}

      <p className={styles[`${variant}Body`]}>
        {icon && <Icon {...icon} />}
        <span className={styles[`${variant}Message`]}>{message}</span>
        {actionButton}
      </p>
    </aside>
  );
};

export default FloatingNotification;
